import React from 'react'
import { Link } from 'gatsby'
import RightArrow from '../assets/right.svg'

const Banner = () => {
  return (
    <section className="main">
      <div className="container">
        <div className="row">
          <h2>
            <div className="line">
              <span>Coaching your team</span>
            </div>
            <div className="line">
              <span>to Grow Beyond</span>
            </div>
          </h2>
          <div className="btn-row">
            <Link to="/contact">
              Get in touch <RightArrow />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
