import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import '../styles/base.scss'

import Intro from '../components/Intro'
import Banner from '../components/Banner'

const homeAnimation = (completeAnimation) => {
  const tl = gsap.timeline()

  tl.from('.line span', 1.8, {
    y: 100,
    ease: 'power4.out',
    delay: 1,
    skewY: 7,
    stagger: 0.3
  }).to('.overlay', 1.6, {
    height: 0,
    ease: 'expo.inOut',
    stagger: 0.4
  })
    .to('.intro-overlay', 0, { css: { display: 'none' } })
}

const Homepage = () => {
  const [animationComplete, setAnimationComplete] = useState(false)

  const completeAnimation = () => {
    setAnimationComplete(!animationComplete)
  }

  useEffect(() => {
    homeAnimation(completeAnimation)
  }, [])
  return (
    <>
      {animationComplete === false ? <Intro /> : ''}
      <Banner />
      {/* <Cases /> */}
    </>
  )
}

export default Homepage
